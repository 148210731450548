import axios from 'axios'

const properties = require('../properties.json');

const baseUrl = properties['Network']['BaseUrl'];

export class UserService {

  getUserById(id){
    return axios.get(
      `${baseUrl}/Users/GetById/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
      }
    ).then(response => response.data).catch(error => error.response)
  }

  getAllUsers () {
    return axios.get(
      `${baseUrl}/Users/GetAll`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
      }
    ).then(response => response.data).catch(error => error.response)
  }
}
