import React  from 'react';
import { Route, Redirect } from 'react-router-dom';

const RequireAuth = ({children, ...rest}) => {
  const authToken = sessionStorage.getItem('token');
  const doAuth = authToken != null && sessionStorage.getItem('expiry') > new Date().toString();
  if(!doAuth){
    sessionStorage.clear();
  }
  return (
    <Route {...rest} render={({location}) => {
      return doAuth
        ? children
        : <Redirect to={{
          pathname: '/login',
          state: {from: location}
        }} />
    }} />
  );
}

export default RequireAuth;
