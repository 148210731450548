import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {UserService} from "../../service/UserService";

const Users = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    new UserService().getAllUsers().then(data => setUsers(data)).catch(error => console.log(error));
  }, [])

  const headerTemplate = (data) => {
    return (
      <React.Fragment>
        <span className="image-text font-bold">{data.clientAccountName}</span>
      </React.Fragment>
    );
  }

  const footerTemplate = (data) => {
    return (
      <React.Fragment>
        <td colSpan="4" style={{ textAlign: 'right' }} className="text-bold pr-6">Total Users</td>
        <td>{calculateCustomerTotal(data.clientAccountId)}</td>
      </React.Fragment>
    );
  }

  const calculateCustomerTotal = (clientAccountId) => {
    let total = 0;
    if (users) {
      for (let user of users) {
        if (user.clientAccountId === clientAccountId) {
          total++;
        }
      }
    }
    return total;
  }

    return (
        <div className="grid table-demo">
          <div className="col-12">
            <Link to="/users-add">
              <Button label="Add New User" icon="pi pi-plus"/>
            </Link>
            <div className="card">
              <h5>User Accounts</h5>
              <DataTable value={users} rowGroupMode="subheader" groupRowsBy="clientAccountId"
                         sortMode="single" sortField="clientAccountId" sortOrder={1} scrollable scrollHeight="400px"
                         rowGroupHeaderTemplate={headerTemplate} rowGroupFooterTemplate={footerTemplate} responsiveLayout="scroll">
                <Column field="userName" header="User Name" style={{ minWidth: '200px' }} />
                <Column field="email" header="Email" style={{ minWidth: '200px' }} />
                <Column field="role" header="Role" style={{ minWidth: '200px' }} />
                <Column header="View User Details" style={{ minWidth: '12rem' }} body={(props) => {
                  return <Link to={`user/${props.id}`}>
                    <Button type="button" icon="pi pi-external-link" />
                  </Link>
                }}
                />
              </DataTable>
            </div>
          </div>
        </div>
    );
}

export default React.memo(Users);
