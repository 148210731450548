import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { ClientTransactionsService } from '../service/ClientTransactionsService'
import { FilterMatchMode } from 'primereact/api'

const Transactions = () => {
  const [transactions, setTransactions] = useState([])
  const [loading1, setLoading1] = useState(true)
  const [filters2, setFilters2] = useState({
    'global': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS
    },
    'TransactionStatusText': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS
    },
    'Sms.Text': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS
    },
    'Sms.Receiver': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS
    },
    'Sms.SenderId.Name': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS
    },
    'Description': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS
    },
    'CreatedDate': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS
    },
  })

  useEffect(() => {
    const ClientId = JSON.parse(sessionStorage.getItem('authData'))?.ClientId
    new ClientTransactionsService().getAllClientTransactions(ClientId).then(data => {
      setTransactions(data)
      setLoading1(false)
    }).catch(error => console.log(error));
  }, [])

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          <h5>All Transactions</h5>
          <DataTable value={transactions} paginator className="p-datatable-gridlines" showGridlines rows={10}
                     dataKey="id" responsiveLayout="scroll" emptyMessage="No transactions found." loading={loading1}
                     filters={filters2} filterDisplay="row"
                     globalFilterFields={['TransactionStatusText', 'Sms.Text', 'Sms.Receiver', 'Sms.SenderId.Name', 'Description', 'CreatedDate']}
          >
            <Column field="TransactionStatusText" header="Status" style={{ minWidth: '12rem' }} filter
                    filterPlaceholder="Search by status"
            />
            <Column field="Sms.Text" header="Sms" style={{ minWidth: '12rem' }} filter
                    filterPlaceholder="Search by text"
            />
            <Column field="Sms.Receiver" header="Receiver" style={{ minWidth: '12rem' }} filter
                    filterPlaceholder="Search by Receiver"
            />
            <Column field="Sms.SenderId.Name" header="Sender" style={{ minWidth: '12rem' }} filter
                    filterPlaceholder="Search by Sender"
            />
            <Column field="Description" header="Description" style={{ minWidth: '12rem' }} filter
                    filterPlaceholder="Search by Description"
            />
            <Column field="CreatedDate" header="Created Date" style={{ minWidth: '12rem' }} filter
                    filterPlaceholder="Search by Date"
            />
          </DataTable>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Transactions)
