import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'
import { ClientService } from '../../service/ClientService'
import { Button } from 'primereact/button'

const Clients = () => {
  const [clients, setClients] = useState([])
  const [loading1, setLoading1] = useState(true);

  useEffect(() => {
    new ClientService().getAllClients().then(data => {
      setClients(data);
      setLoading1(false);
    }).catch(error => console.log(error))
  }, [])

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <Link to="/clients/add">
          <Button label="Add New Client" icon="pi pi-plus"/>
        </Link>
        <div className="card">
          <h5>All Clients</h5>
          <DataTable value={clients} paginator className="p-datatable-gridlines" showGridlines rows={5}
                     dataKey="id" responsiveLayout="scroll" emptyMessage="No clients found." loading={loading1}>
            <Column field="name" header="Name" style={{ minWidth: '12rem' }}/>
            <Column field="pricePerMessage" header="Price Per Message" style={{ minWidth: '12rem' }}/>
            <Column field="creditBalance" header="Balance Amount" style={{ minWidth: '12rem' }}/>
            <Column header="View Client" style={{ minWidth: '12rem' }} body={(props) => {
              return <Link to={`client/${props.id}`}>
                <Button type="button" icon="pi pi-external-link" />
              </Link>
            }}
            />
          </DataTable>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Clients)
