import React from 'react'
import {Link} from "react-router-dom";
import {Button} from "primereact/button";

const AccessDenied = () => {

  return (

    <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div className="text-700 text-center">
        <div className="text-blue-600 font-bold mb-3"><i className="pi pi-ban"></i>&nbsp;Error 401</div>
        <div className="text-900 font-bold text-5xl mb-3">UnAuthorized</div>
        <div className="text-700 text-2xl mb-5">You do not have access to this resource</div>
        <Link to="/">
          <Button label="Go To Dashboard" icon="pi pi-replay" className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
        </Link>
      </div>
    </div>

  )
}

export default React.memo(AccessDenied)
