import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner'
import { Toast } from 'primereact/toast';
import { ClientService } from '../service/ClientService';
import { SmsService } from '../service/SmsService';
import React, { useEffect, useRef, useState } from 'react';

const BulkSms = () => {

  const [senderId, setSenderId] = useState(null);
  const [file, setFile] = useState(null);
  const [text, setText] = useState('');
  const toast = useRef(null);
  const [senderIds, setSenderIds] = useState(null);
  const [displayBasic, setDisplayBasic] = useState(false);

  useEffect(() => {
    const ClientId = JSON.parse(sessionStorage.getItem('authData'))?.ClientId;
    new ClientService().getClientById(ClientId).then( data => setSenderIds(data?.senderIds)).catch( error => console.log(error))
  }, [])

  const showMessage = (_severity, _summary, _detail) => {
    toast.current.show({
      severity: _severity,
      summary: _summary,
      detail: _detail,
      life: 10000
    })
  }

  const onSendMessage = (e) => {
    e.preventDefault();
    setDisplayBasic(true);
    if (validForm() === true) {
      new SmsService().sendBulkSms(senderId?.id, text, file)
        .then(data => {
          setDisplayBasic(false);
          const status = data?.status;
          const failedCount = data?.failedCount;
          const successCount = data?.successCount;
          if(status === 'DONE'){
            showMessage('success', status, `Successful Count: ${successCount}, Failed Count: ${failedCount}`);
          } else {
            showMessage('error', 'Error', 'Error');
          }
        })
        .catch(error => {
          setDisplayBasic(false);
        })
    }
    setFile(null)
    setText('')
    setSenderId(null)
  }

  const onUpload = (e) => {
    setFile(e.target.files[0])
  }

  const validForm = () => {
    if (!senderId || !text || !file) {
      setDisplayBasic(false);
      showMessage('error', 'Error', 'Form Not Valid');
      return false
    }
    return true;
  }

    return (
      <div className="grid">
        <div className="col-12">
          <div className="card">
            <h5>Send Bulk SMS</h5>
            <Toast ref={toast}/>
            <form>
              <h6>Sender Id</h6>
              <Dropdown value={senderId} onChange={(e) => setSenderId(e.value)} options={senderIds}
                        optionLabel="name" placeholder="Select" required
              />
              <h6>Upload File</h6>
              <input type="file" name="file" onChange={(e) => onUpload(e)}
                required accept=".xlsx"/>

              <h6>Default Message</h6>
              <InputTextarea placeholder="Your Message" autoResize rows="3" cols="30"
                             value={text} onChange={(e) => setText(e.target.value)} required
              />
              <br/>
              <br/>
              <Button label="Send SMS" icon="pi pi-send" iconPos="right" className="mr-2 mb-2"
                      onClick={(e) => {
                        onSendMessage(e)
                      }}
              />
              <Dialog header="Please Wait ..." visible={displayBasic} style={{ width: '20vw' }} closable={false}>
                <ProgressSpinner />
              </Dialog>
            </form>
          </div>
        </div>
      </div>
    );
}

export default React.memo(BulkSms);
