import axios from 'axios'

const properties = require('../properties.json');

const baseUrl = properties['Network']['BaseUrl'];

export class AuthService {

  login (email, password) {
    return axios.post(
      `${baseUrl}/auth/login`,
      {
        'email': email,
        'password': password
      },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    ).then(response => response.data).catch(error => error.response)
  }

  addUserAccount(data){
    return axios.post(
      `${baseUrl}/Auth/AddUserAccount`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
      }
    ).then(response => response.data).catch(error => error.response)
  }

}
