import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Toast } from 'primereact/toast'

import React, { useEffect, useRef, useState } from 'react'
import { ClientService } from '../service/ClientService'
import { SmsService } from '../service/SmsService'

const SingleSms = () => {
  const [senderId, setSenderId] = useState(null)
  const [receiver, setReceiver] = useState('')
  const [text, setText] = useState('')
  const toast = useRef(null)
  const [senderIds, setSenderIds] = useState(null)
  const [displayBasic, setDisplayBasic] = useState(false);

  useEffect(() => {
    const ClientId = JSON.parse(sessionStorage.getItem('authData'))?.ClientId;
    new ClientService().getClientById(ClientId).then( data => setSenderIds(data?.senderIds)).catch( error => console.log(error))
  }, [])

  const showMessage = (_severity, _summary, _detail) => {
    toast.current.show({
      severity: _severity,
      summary: _summary,
      detail: _detail,
      life: 5000
    })
  }

  const onSendMessage = (e) => {
    e.preventDefault();
    setDisplayBasic(true);
    if (validForm() === true) {
      new SmsService().sendSingleSms(senderId?.id, receiver, text)
        .then(data => {
          setDisplayBasic(false);
          const status = data?.status;
          const description = data?.description;
          if(status === 'FAILED'){
            showMessage('error', status, description);
          } else {
            showMessage('success', status, description);
          }
        })
        .catch(error => {
          setDisplayBasic(false);
        })
    }
    setReceiver('')
    setText('')
    setSenderId(null)
  }

  const validForm = () => {
    if (!senderId || !receiver || !text) {
      setDisplayBasic(false);
      showMessage('error', 'Error', 'Form Not Valid');
      return false
    }
    if(receiver.length !== 12){
      setDisplayBasic(false);
      showMessage('error', 'Error', 'Invalid receiver. Use the format 263771222333');
      return false
    }
    return true
  }

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <h5>Send Single SMS</h5>
          <Toast ref={toast}/>
          <form>
            <h6>Sender Id</h6>
            <Dropdown value={senderId} onChange={(e) => setSenderId(e.value)} options={senderIds}
                      optionLabel="name" placeholder="Select" required
            />
            <h6>Receiver</h6>
            <InputText id="receiver" type="number" placeholder="263771222333" required
                       value={receiver} onChange={(e) => setReceiver(e.target.value)}
            />
            <h6>Message</h6>
            <InputTextarea placeholder="Your Message" autoResize rows="3" cols="30"
                           value={text} onChange={(e) => setText(e.target.value)} required
            />
            <br/>
            <br/>
            <Button label="Send SMS" icon="pi pi-send" iconPos="right" className="mr-2 mb-2"
                    onClick={(e) => {
                      onSendMessage(e)
                    }}
            />
            <Dialog header="Please Wait ..." visible={displayBasic} style={{ width: '20vw' }} closable={false}>
              <ProgressSpinner />
            </Dialog>
          </form>
        </div>
      </div>
    </div>
  )
}

export default React.memo(SingleSms)
