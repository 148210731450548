import axios from 'axios'

const properties = require('../properties.json');

const baseUrl = properties['Network']['BaseUrl'];

export class SmsService {
  sendSingleSms(senderId, receiver, text){
    return axios.post(
      `${baseUrl}/Sms/SendSingleSms`,
      {
        senderId: senderId,
        receiver: receiver,
        text: text
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
      }
    ).then(response => response.data).catch(error => error.response)
  }

  sendBulkSms(senderId, defaultText, uploadFile){
    let bodyFormData = new FormData();
    bodyFormData.append('uploadFile', uploadFile);
    return axios.post(
      `${baseUrl}/Sms/SendBulkSms?senderId=${senderId}&defaultMessage=${defaultText}`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
      }
    ).then(response => response.data).catch(error => error.response)
  }
}
