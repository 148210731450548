import classNames from 'classnames'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'

import PrimeReact from 'primereact/api'

import 'primereact/resources/primereact.css'
import { Tooltip } from 'primereact/tooltip'
import 'prismjs/themes/prism-coy.css'
import React, { useEffect, useRef, useState } from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import './App.scss'
import { AppConfig } from './AppConfig'
import { AppFooter } from './AppFooter'
import { AppMenu } from './AppMenu'

import { AppTopbar } from './AppTopbar'
import './assets/demo/Demos.scss'
import './assets/demo/flags/flags.css'
import './assets/layout/layout.scss'
import AddClient from './components/clients/AddClient'
import ClientDetail from './components/clients/Client'
import Clients from './components/clients/Clients'
import Transactions from './components/ClientTransactions'

import Dashboard from './components/Dashboard'
import BulkSms from './components/SendBulkSms'
import SingleSms from './components/SendSingleSms'
import AddUser from './components/users/AddUser'
import UserDetail from './components/users/UserDetail'
import Users from './components/users/Users'
import AccessDenied from "./components/AccessDenied";

const App = (...rest) => {
  const [layoutMode, setLayoutMode] = useState('static')
  const [layoutColorMode, setLayoutColorMode] = useState('light')
  const [inputStyle, setInputStyle] = useState('outlined')
  const [ripple, setRipple] = useState(true)
  const [staticMenuInactive, setStaticMenuInactive] = useState(false)
  const [overlayMenuActive, setOverlayMenuActive] = useState(false)
  const [mobileMenuActive, setMobileMenuActive] = useState(false)
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false)
  const [logout, setLogout] = useState(false)
  const copyTooltipRef = useRef()
  const location = useLocation()
  const isAdmin = sessionStorage.getItem('role') === 'Admin';

  PrimeReact.ripple = true

  let menuClick = false
  let mobileTopbarMenuClick = false

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, 'body-overflow-hidden')
    } else {
      removeClass(document.body, 'body-overflow-hidden')
    }
  }, [mobileMenuActive])

  useEffect(() => {
    copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents()
  }, [location])

  if (logout === true) {
    return <Redirect to="/"/>
  }

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle)
  }

  const onRipple = (e) => {
    PrimeReact.ripple = e.value
    setRipple(e.value)
  }

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode)
  }

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode)
  }

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false)
      setMobileMenuActive(false)
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false)
    }

    mobileTopbarMenuClick = false
    menuClick = false
  }

  const onToggleMenuClick = (event) => {
    menuClick = true

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true)
        }

        setOverlayMenuActive((prevState) => !prevState)
        setMobileMenuActive(false)
      } else if (layoutMode === 'static') {
        setStaticMenuInactive((prevState) => !prevState)
      }
    } else {
      setMobileMenuActive((prevState) => !prevState)
    }

    event.preventDefault()
  }

  const onSidebarClick = () => {
    menuClick = true
  }

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true

    setMobileTopbarMenuActive((prevState) => !prevState)
    event.preventDefault()
  }

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true

    event.preventDefault()
  }

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false)
      setMobileMenuActive(false)
    }
  }
  const isDesktop = () => {
    return window.innerWidth >= 992
  }

  const menu = [
    {
      label: 'Home',
      items: [
        {
          label: 'Dashboard',
          icon: 'pi pi-fw pi-home',
          to: '/'
        }
      ]
    },
    {
      label: 'Sms Actions',
      icon: 'pi pi-fw pi-sitemap',
      items: [
        {
          label: 'Single Sms',
          icon: 'pi pi-fw pi-envelope',
          to: '/single-sms'
        },
        {
          label: 'Bulk Sms',
          icon: 'pi pi-fw pi-map',
          to: '/bulk-sms'
        },
      ]
    },
    {
      label: 'Reports',
      icon: 'pi pi-fw pi-file-excel',
      items: [
        {
          label: 'Transactions',
          icon: 'pi pi-fw pi-file-excel',
          to: '/transactions'
        },
      ]
    },
    {
      label: 'Admin Actions',
      items: [
        {
          label: 'Clients',
          icon: 'pi pi-fw pi-briefcase',
          to: '/clients',
        },
        {
          label: 'Users',
          icon: 'pi pi-fw pi-users',
          to: '/users',
        },
      ]
    },
    {
      label: 'Settings',
      items: [
        {
          label: 'Sign Out',
          icon: 'pi pi-fw pi-sign-out',
          command: () => {
            sessionStorage.clear()
            setLogout(true)
          }
        }
      ]
    }
  ]

  const addClass = (element, className) => {
    if (element.classList) {
      element.classList.add(className)
    } else {
      element.className += ' ' + className
    }
  }

  const removeClass = (element, className) => {
    if (element.classList) {
      element.classList.remove(className)
    } else {
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
    }
  }

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    'layout-theme-light': layoutColorMode === 'light'
  })

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard"
               event="focus"
      />

      <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                 mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                 onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode}/>
      </div>

      <div className="layout-main-container">
        <div className="layout-main">
          <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} location={location}/>}/>
          <Route path="/single-sms" component={SingleSms}/>
          <Route path="/bulk-sms" component={BulkSms}/>
          <Route path="/transactions" exact component={Transactions}/>
          <Route path="/clients" {...rest} render={(props) =>
              isAdmin ? <Clients {...props} /> : <Redirect to="/401" />}/>
           <Route path="/clients/add" {...rest} render={(props) =>
              isAdmin ? <AddClient {...props} /> : <Redirect to="/401" />}/>
          <Route path="/client/:id" exact {...rest} render={(props) =>
              isAdmin ? <ClientDetail {...props} /> : <Redirect to="/401" />}/>
          <Route path="/users" {...rest} render={(props) =>
              isAdmin ? <Users {...props} /> : <Redirect to="/401" />}/>
          <Route path="/users-add" {...rest} render={(props) =>
              isAdmin ? <AddUser {...props} /> : <Redirect to="/401" />}/>
          <Route path="/user/:id" exact {...rest} render={(props) =>
              isAdmin ? <UserDetail {...props} /> : <Redirect to="/401" />}/>
          <Route path="/401" exact component={AccessDenied}/>
        </div>

        <AppFooter layoutColorMode={layoutColorMode}/>
      </div>

      <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle}
                 onInputStyleChange={onInputStyleChange}
                 layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode}
                 onColorModeChange={onColorModeChange}
      />

      <CSSTransition classNames="layout-mask" timeout={{
        enter: 200,
        exit: 200
      }} in={mobileMenuActive} unmountOnExit
      >
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  )
}
export default App
