import { Accordion, AccordionTab } from 'primereact/accordion'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { InputNumber } from 'primereact/inputnumber'
import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { ClientService } from '../../service/ClientService'
import { ListBox } from 'primereact/listbox'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'

const ClientDetail = () => {
  const params = useParams()
  const [client, setClient] = useState({})
  const [senderIds, setSenderIds] = useState([])
  const [selectedCity, setSelectedCity] = useState(null)
  const [clientName, setClientName] = useState('')
  const [clientPricePerMessage, setClientPricePerMessage] = useState(0)
  const [rechargeAmount, setRechargeAmount] = useState(0)
  const [senderId, setSenderId] = useState('')
  const [visible1, setVisible1] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [visible3, setVisible3] = useState(false)
  const [visible4, setVisible4] = useState(false)

  useEffect(() => {
    new ClientService().getClientById(params.id).then(data => {
      setClient(data);
      setSenderIds(data?.senderIds);
    }).catch(error => console.log(error))
  }, [params])

  const changeClientName = () => {
    new ClientService().updateClient(client.id, clientName, client.pricePerMessage, client.creditBalance)
      .then(data => setClient(data)).catch(error => console.log(error))
    return <Redirect to={`client/${client.id}`}/>
  }

  const changeClientPricePerMessage = () => {
    new ClientService().updateClient(client.id, client.name, clientPricePerMessage, client.creditBalance)
      .then(data => setClient(data)).catch(error => console.log(error));
    return <Redirect to={`client/${client.id}`}/>
  }

  const rechargeClientCreditAmount = () => {
    const amount = Number(rechargeAmount) + Number(client.creditBalance);
    new ClientService().updateClient(client.id, client.name, client.pricePerMessage, amount)
      .then(data => setClient(data)).catch(error => console.log(error));
    return <Redirect to={`client/${client.id}`}/>
  }

  const addSenderId = () => {
    new ClientService().addSenderId(client.id, senderId)
      .then(data => {
        client.senderIds.push(data);
        setClient(client);
      }).catch(error => console.log(error));
    return <Redirect to={`client/${client.id}`}/>
  }


  const reject = () => {
    console.log('rejected')
  }

  return (
    <div className="grid">
      <div className="col-12 md:col-6">
        <div className="card">
          <h4>{client.name}</h4>
          <h6>Price Per Message ($)</h6>
          <span>{client.pricePerMessage}</span>
          <br/><br/>
          <h6>Credit Balance ($)</h6>
          <span>{client.creditBalance}</span>
          <br/><br/>
          <h6>Sender IDs</h6><br/>
          <ListBox value={selectedCity} options={senderIds} onChange={(e) => setSelectedCity(e.value)}
                   optionLabel="name" style={{ width: '15rem' }}
          />
        </div>
      </div>

      <div className="col-12 md:col-6">
        <div className="card">
          <h5>Actions</h5>
          <Accordion>
            <AccordionTab header="Change Client Name">
              <InputText value={clientName} onChange={(e) => setClientName(e.target.value)}/>
              <ConfirmDialog visible={visible1} onHide={() => setVisible1(false)} message="Are you sure?"
                             header="Confirmation" icon="pi pi-exclamation-triangle" accept={changeClientName}
                             reject={reject}
              />
              <Button onClick={() => setVisible1(true)} icon="pi pi-save" label="Save Name"/>
            </AccordionTab>
            <AccordionTab header="Change Client Price Per Message">
              <InputNumber value={clientPricePerMessage} onChange={(e) => setClientPricePerMessage(e.value)}
                           mode="decimal" maxFractionDigits={2}/>
              <ConfirmDialog visible={visible2} onHide={() => setVisible2(false)} message="Are you sure?"
                             header="Confirmation" icon="pi pi-exclamation-triangle"
                             accept={changeClientPricePerMessage} reject={reject}
              />
              <Button onClick={() => setVisible2(true)} icon="pi pi-save" label="Save Price"/>
            </AccordionTab>
            <AccordionTab header="Recharge Client Account">
              <InputNumber value={rechargeAmount} onChange={(e) => setRechargeAmount(e.value)}
                           mode="decimal" maxFractionDigits={2}/>
              <ConfirmDialog visible={visible3} onHide={() => setVisible3(false)} message="Are you sure?"
                             header="Confirmation" icon="pi pi-exclamation-triangle"
                             accept={rechargeClientCreditAmount} reject={reject}
              />
              <Button onClick={() => setVisible3(true)} icon="pi pi-save" label="Recharge"/>
            </AccordionTab>
            <AccordionTab header="Add Sender ID">
              <InputText value={senderId} onChange={(e) => setSenderId(e.target.value)}/>
              <ConfirmDialog visible={visible4} onHide={() => setVisible4(false)} message="Are you sure?"
                             header="Confirmation" icon="pi pi-exclamation-triangle" accept={addSenderId}
                             reject={reject}
              />
              <Button onClick={() => setVisible4(true)} icon="pi pi-save" label="Save Sender Id"/>
            </AccordionTab>
          </Accordion>
        </div>
      </div>

    </div>
  )
}

export default React.memo(ClientDetail)
