import axios from 'axios'

const properties = require('../properties.json');

const baseUrl = properties['Network']['BaseUrl'];

export class ClientService {

  getClientById(id){
    return axios.get(
      `${baseUrl}/Clients/GetById/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
      }
    ).then(response => response.data).catch(error => error.response)
  }

  getAllClients(){
    return axios.get(
      `${baseUrl}/Clients/GetAll`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
      }
    ).then(response => response.data).catch(error => error.response)
  }

  addNewClient(_name, _pricePerMessage=0, _creditBalance=0){
    return axios.post(
      `${baseUrl}/Clients/AddClientAccount`,
      {
      name: _name,
      pricePerMessage: _pricePerMessage,
      creditBalance: _creditBalance
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
      }
    ).then(response => response.data).catch(error => error.response)
  }

  updateClient(_id, _name, _pricePerMessage, _creditBalance){
    return axios.post(
      `${baseUrl}/Clients/Update/${_id}`,
      {
        name: _name,
        pricePerMessage: _pricePerMessage,
        creditBalance: _creditBalance
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
      }
    ).then(response => response.data).catch(error => error.response)
  }

  addSenderId(_clientId, _name){
    return axios.post(
      `${baseUrl}/Clients/AddSenderId`,
      {
        name: _name,
        clientAccountId: _clientId
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
      }
    ).then(response => response.data).catch(error => error.response)
  }
}
