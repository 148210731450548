import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { Redirect } from 'react-router-dom'
import { ClientService } from '../../service/ClientService'

const AddClient = () => {

  const [showMessage, setShowMessage] = useState(false)
  const [formData, setFormData] = useState({})
  const [success, setSuccess] = useState(false)

  const defaultValues = {
    name: '',
    pricePerMessage: 0,
    creditBalance: 0,
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({ defaultValues })

  if (success === true) {
    return <Redirect to={'/clients'}/>
  }

  const onSubmit = (data) => {
    setFormData(data)
    new ClientService().addNewClient(data.name, data.pricePerMessage, data.creditBalance)
      .then(r => {
        setShowMessage(true);
      })
    reset()
  }

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  const dialogFooter = <div className="flex justify-content-center">
    <Button label="OK" className="p-button-text" autoFocus onClick={() => {
      setShowMessage(false)
      setSuccess(true)
    }}
    /></div>

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <div className="form-demo">
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter}
                    showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}
            >
              <div className="flex justify-content-center flex-column pt-6 px-3">
                <i className="pi pi-check-circle" style={{
                  fontSize: '5rem',
                  color: 'var(--green-500)'
                }}
                ></i>
                <h5>Client Save Successful!</h5>
                <p style={{
                  lineHeight: 1.5,
                  textIndent: '1rem'
                }}
                >
                  Your client <b>{formData.name}</b> has been saved
                </p>
              </div>
            </Dialog>

            <div className="flex justify-content-center">
              <div className="card">
                <h5 className="text-center">Save Client</h5>
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                  <div className="field">
                            <span className="p-float-label">
                                <Controller name="name" control={control} rules={{ required: 'Name is required.' }}
                                            render={({
                                              field,
                                              fieldState
                                            }) => (
                                              <InputText id={field.name} {...field} autoFocus
                                                         className={classNames({ 'p-invalid': fieldState.invalid })}
                                              />
                                            )}
                                />
                                <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Name*</label>
                            </span>
                    {getFormErrorMessage('name')}
                  </div>
                  <br/>
                  <div className="field">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-money-bill"/>
                                <Controller name="pricePerMessage" control={control}
                                            rules={{
                                              pattern: {
                                                value: /^\d+\.?\d*$/,
                                                message: 'Amount Invalid'
                                              }
                                            }}
                                            render={({
                                              field,
                                              fieldState
                                            }) => (
                                              <InputText id={field.name} {...field}
                                                         className={classNames({ 'p-invalid': fieldState.invalid })}
                                              />
                                            )}
                                />
                                <label htmlFor="pricePerMessage">Client's Price Per Message</label>
                            </span>
                    {getFormErrorMessage('pricePerMessage')}
                  </div>
                  <br/>
                  <div className="field">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-money-bill"/>
                                <Controller name="creditBalance" control={control}
                                            rules={{
                                              pattern: {
                                                value: /^\d+\.?\d*$/,
                                                message: 'Amount invalid'
                                              }
                                            }}
                                            render={({
                                              field,
                                              fieldState
                                            }) => (
                                              <InputText id={field.name} {...field}
                                                         className={classNames({ 'p-invalid': fieldState.invalid })}
                                              />
                                            )}
                                />
                                <label htmlFor="creditBalance">Balance $</label>
                            </span>
                    {getFormErrorMessage('creditBalance')}
                  </div>

                  <Button type="submit" label="Save" className="mt-2"/>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AddClient)
