import { Dropdown } from 'primereact/dropdown'
import { Password } from 'primereact/password'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { Redirect } from 'react-router-dom'
import { AuthService } from '../../service/AuthService'
import { ClientService } from '../../service/ClientService'

const AddUser = () => {

  const [showMessage, setShowMessage] = useState(false)
  const [formData, setFormData] = useState({})
  const [success, setSuccess] = useState(false)
  const [clients, setClients] = useState([])

  useEffect(() => {
    new ClientService().getAllClients().then(data => setClients(data))
  }, [])

  const defaultValues = {
    userName: '',
    email: '',
    password: '',
    clientAccount: null,
    role: ''
  }

  const roles = [
    'Client',
    'Admin'
  ]

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({ defaultValues })

  if (success === true) {
    return <Redirect to={'/users'}/>
  }

  const onSubmit = (data) => {
    const dataToSave = {
      "userName": data.userName,
      "email": data.email,
      "password": data.password,
      "clientAccountId": Number(data.clientAccount.id),
      "role": data.role
    }
    setFormData(data)
    new AuthService().addUserAccount(dataToSave)
      .then(r => {
        console.log(r)
        setShowMessage(true);
      });
    reset()
  }

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  const dialogFooter = <div className="flex justify-content-center">
    <Button label="OK" className="p-button-text" autoFocus onClick={() => {
      setShowMessage(false)
      setSuccess(true)
    }}
    /></div>

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <div className="form-demo">
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter}
                    showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}
            >
              <div className="flex justify-content-center flex-column pt-6 px-3">
                <i className="pi pi-check-circle" style={{
                  fontSize: '5rem',
                  color: 'var(--green-500)'
                }}
                ></i>
                <h5>User Save Successful!</h5>
                <p style={{
                  lineHeight: 1.5,
                  textIndent: '1rem'
                }}
                >
                  Your user <b>{formData.name}</b> has been saved
                </p>
              </div>
            </Dialog>

            <div className="flex justify-content-center">
              <div className="card">
                <h5 className="text-center">Save User</h5>
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">

                  <div className="field">
                            <span className="p-float-label">
                                <Controller name="clientAccount" control={control} render={({ field }) => (
                                  <Dropdown id={field.id} value={field.value} onChange={(e) => field.onChange(e.value)}
                                            options={clients} optionLabel="name"
                                  />
                                )}
                                />
                                <label htmlFor="clientAccount">Client Account</label>
                            </span>
                  </div>
                  <br/>
                  <div className="field">
                            <span className="p-float-label">
                                <Controller name="userName" control={control}
                                            rules={{ required: 'User Name is required.' }}
                                            render={({
                                              field,
                                              fieldState
                                            }) => (
                                              <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })}
                                              />
                                            )}
                                />
                                <label htmlFor="userName" className={classNames({ 'p-error': errors.name })}
                                >User Name*</label>
                            </span>
                    {getFormErrorMessage('userName')}
                  </div>
                  <br/>
                  <div className="field">
                            <span className="p-float-label">
                                <Controller name="email" control={control}
                                            rules={{
                                              required: 'Email is required.',
                                              pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Invalid email address. E.g. example@email.com'
                                              }
                                            }}
                                            render={({
                                              field,
                                              fieldState
                                            }) => (
                                              <InputText id={field.name} {...field}
                                                         className={classNames({ 'p-invalid': fieldState.invalid })}
                                              />
                                            )}
                                />
                                <label htmlFor="email" className={classNames({ 'p-error': errors.name })}>Email*</label>
                            </span>
                    {getFormErrorMessage('email')}
                  </div>
                  <br/>
                  <div className="field">
                            <span className="p-float-label">
                                <Controller name="password" control={control}
                                            rules={{ required: 'Password is required.' }} render={({
                                  field,
                                  fieldState
                                }) => (
                                  <Password id={field.name} {...field} toggleMask
                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                  />
                                )}
                                />
                                <label htmlFor="password" className={classNames({ 'p-error': errors.password })}
                                >Password*</label>
                            </span>
                    {getFormErrorMessage('password')}
                  </div>
                  <br/>
                  <div className="field">
                            <span className="p-float-label">
                                <Controller name="role" control={control} render={({ field }) => (
                                  <Dropdown id={field.name} value={field.value}
                                            onChange={(e) => field.onChange(e.value)} options={roles}
                                  />
                                )}
                                />
                                <label htmlFor="role">Role</label>
                            </span>
                  </div>

                  <Button type="submit" label="Save" className="mt-2"/>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AddUser)
