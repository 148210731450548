import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Login from './components/Login'
import RequireAuth from './components/RequireAuth'
import { AuthProvider } from './context/AuthProvider'
import { BrowserRouter, Route } from 'react-router-dom'
import {Switch} from 'react-router'

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <Switch>
        <Route path="/login" exact component={Login}/>
        <RequireAuth path="/" >
          <App />
        </RequireAuth>
      </Switch>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
