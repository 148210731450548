import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ClientService } from '../service/ClientService'
import { ClientTransactionsService } from '../service/ClientTransactionsService'

const Dashboard = (props) => {

  const [transactions, setTransactions] = useState([])
  const [client, setClient] = useState({})

  useEffect(() => {
    const authData = JSON.parse(sessionStorage.getItem('authData'));
    const ClientId = authData?.ClientId;
    new ClientTransactionsService().getAllClientTransactions(ClientId).then(data => setTransactions(data)).catch(error => console.log(error));
    new ClientService().getClientById(ClientId).then(data => setClient(data)).catch(error => console.log(error));
    const role = authData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    if(role)
      sessionStorage.setItem('role', role);
  }, [])

  return (
    <div className="grid">
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Balance</span>
              <div className="text-900 font-medium text-xl">${client.creditBalance}</div>
            </div>
            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{
              width: '2.5rem',
              height: '2.5rem'
            }}
            >
              <i className="pi pi-money-bill text-blue-500 text-xl"/>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <Link to="/transactions">
          <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">Total SMS sent</span>
                <div className="text-900 font-medium text-xl">{transactions.length}</div>
              </div>
              <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{
                width: '2.5rem',
                height: '2.5rem'
              }}
              >
                <i className="pi pi-inbox text-orange-500 text-xl"/>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <Link to="/transactions">
          <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">Successful SMS</span>
                <div className="text-900 font-medium text-xl">
                  {transactions.filter(transaction =>
                    transaction.TransactionStatusText === 'Successful').length}
                </div>
              </div>
              <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{
                width: '2.5rem',
                height: '2.5rem'
              }}
              >
                <i className="pi pi-inbox text-cyan-500 text-xl"/>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <Link to="/transactions">
          <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">Failed SMS</span>
                <div className="text-900 font-medium text-xl">
                  {transactions.filter(transaction =>
                    transaction.TransactionStatusText === 'Failed').length}
                </div>
              </div>
              <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{
                width: '2.5rem',
                height: '2.5rem'
              }}
              >
                <i className="pi pi-inbox text-purple-500 text-xl"/>
              </div>
            </div>
          </div>
        </Link>
      </div>

    </div>
  )
}

export default React.memo(Dashboard)
