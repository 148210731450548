import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {UserService} from "../../service/UserService";
import {ListBox} from "primereact/listbox";

const UserDetail = () => {
  const params = useParams()
  const [user, setUser] = useState({})

  useEffect( () => {
    new UserService().getUserById(params.id).then(data => {
      console.log(data);
      setUser(data);
    }).catch(error => console.log(error))
  },[params])

    return (
        <div className="grid">
          <div className="col-12 md:col-6">
            <div className="card">
              <h4>{user.userName}</h4>
              <h6>Email</h6>
              <span>{user.email}</span>
              <br/><br/>
              <h6>Created</h6>
              <span>{user.createdDate}</span>
              <br/><br/>
              <h6>Role</h6>
              <span>{user.role}</span>
            </div>
          </div>
        </div>
    );
}

export default React.memo(UserDetail);
